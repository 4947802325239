import React from "react"
import {graphql, Link} from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styles from "./index.module.scss"
import Container from "../components/container"
import Project from "../components/project"

const IndexPage = ({data, location}) => {
  const projects = data.allProject.nodes


  return (
    <Layout className={styles.indexRoot} location={location}>
      <SEO title="Home"/>
      <section className={styles.mainSection}>
        <Container className={styles.index}>
          <div className={styles.headline}>
            <h1 className={styles.title}>I create memorable experiences</h1>
            <h4 className={styles.subtitle}>Freelance mobile and web developer.</h4>
            <Link to="/contact">
              <button className={styles.callToAction}>Let me help you</button>
            </Link>
            <h2 className={styles.toolsetHeadline}>Toolset</h2>
            <ul className={styles.tools}>
              <li>Android</li>
              <li>Kotlin</li>
              <li>MVVM</li>
              <li>Flutter</li>
              <li>React</li>
              <li>Gatsby.js</li>
              <li>Node.js</li>
              <li>Express</li>
            </ul>
          </div>
          <div className={styles.projectsContainer}>
            <div className={styles.projectsHeadline}>
              <div className={styles.projectHeadlineTitle}>My work</div>
              <Link className={styles.link} to="/portfolio">
                <di>- View all my work</di>
              </Link>
            </div>
            <div className={styles.projects}>
              {projects.map(project => (
                <Project key={project.id} project={project} aspectRatio={3 / 4}/>
              ))}
            </div>
          </div>
        </Container>
      </section>
    </Layout>
  )
}

export const query = graphql`
    query {
        allProject(limit: 4) {
            nodes {
                id
                name
                slug
                featuredImg {
                    childImageSharp {
                        fluid {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }
    }
`

export default IndexPage
