import React from 'react';
import PropTypes from 'prop-types';
import Img from "gatsby-image"
import styles from './project.module.scss'
import {Link} from "gatsby"

const Project = props => {
  const {project, aspectRatio} = props

  return (
    <Link to={project.slug}>
      <div className={styles.projectContainer}>
        <Img
          fluid={project.featuredImg.childImageSharp.fluid}
          objectFilt="cover"
          style={{
            borderRadius: "0.5rem",
            marginBottom: "0.5rem"
          }}
          sizes={{...project.featuredImg.childImageSharp.fluid, aspectRatio: aspectRatio}}/>
        <div>{project.name}</div>
      </div>
    </Link>
  );
};

Project.defaultProps = {
  aspectRatio: 1
}

Project.propTypes = {
  aspectRatio: PropTypes.number,
  project: PropTypes.object.isRequired
};

export default Project;